<template>
  <v-text-field
    v-if="editable"
    v-model="number"
    placeholder="숫자 입력"
    label="숫자 입력"
    solo
    flat
    dense
    outlined
    hide-details
    type="number"
    style="width: 100%;"
    :error-messages="numberError"
    @input="v$.number.$touch()"
    @blur="v$.number.$touch()"
  />
  <span v-else>{{ number }}</span>
</template>

<script>
import { decimal, requiredIf } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
  name: "NumberFieldItem",
  setup: () => ({ v$: useVuelidate() }),
  props: {
    value: {
      type: Object,
      description: "필드 데이타 (v-model)"
    },
    templateFrame: {
      type: Object,
      required: true,
      description: "필드 속성"
    },
    editable: {
      type: Boolean,
      default: false,
      description: "편집 모드"
    }
  },
  computed: {
    number: {
      get() {
        return this.value?.value ?? "";
      },
      set(val) {
        this.$emit("input", { value: val });
      }
    },
    numberError() {
      const { $dirty, required, decimal } = this.v$.number;
      const errors = [];
      if (!$dirty) return errors;
      if (required.$invalid) errors.push("필수 입력사항 입니다.");
      if (decimal.$invalid) errors.push("숫자만 입력 가능합니다.");
      return errors;
    }
  },
  methods: {
    async validate() {
      return await this.v$.$validate();
    }
  },
  validations() {
    return {
      number: {
        required: requiredIf(this.templateFrame?.required),
        decimal
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      // 기본값 초기화 (수정일 경우 기존값 할당)
      this.number = this.value?.value ?? "";
    });
  }
};
</script>

<style lang="scss" scoped></style>
